const focusInElement = () => {
  const getFocusableNodes = (element) => {
    const nodes = element.querySelectorAll(
      "a[href], button, details, input:not([type='hidden']), select, [tabindex]:not([tabindex='-1']), textarea"
    );
    return Array(...nodes).filter(
      (element2) => !element2.hasAttribute("disabled") && !element2.getAttribute("aria-hidden")
    );
  };
  const focusElement = (element) => {
    if (!element) {
      return;
    }
    const focusableNodes = getFocusableNodes(element);
    if (focusableNodes.length) {
      focusableNodes[0].focus();
    }
  };
  const retrainFocus = (event, element) => {
    if (!element) {
      return;
    }
    const activeElement = document.activeElement;
    const focusableNodes = getFocusableNodes(element);
    const focusedItemIndex = focusableNodes.indexOf(activeElement);
    if (event.shiftKey && focusedItemIndex === 0) {
      focusableNodes[focusableNodes.length - 1].focus();
      event.preventDefault();
    }
    if (!event.shiftKey && focusableNodes.length > 0 && focusedItemIndex === focusableNodes.length - 1) {
      focusableNodes[0].focus();
      event.preventDefault();
    }
  };
  return {
    focusElement,
    retrainFocus
  };
};
export { focusInElement };
